import React from "react";
import { Link } from "gatsby";
import { ListGroup } from "react-bootstrap";
import "../styles/menu.css";

function Menu() {
  function handleClickIntro(e) {
    // e.preventDefault();
    console.log("Click vào link" + e);
  }

  return (
    <>
      <ListGroup className="fontrem4 menu-background" as="ul">
        <li className="customize-list-group-item">
          <Link to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width={64}
              height={64}
              viewBox="0 0 172 172"
              style={{ fill: "#000000" }}
            >
              <g
                fill="none"
                fillRule="nonzero"
                stroke="none"
                strokeWidth={1}
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit={10}
                strokeDasharray
                strokeDashoffset={0}
                fontFamily="none"
                fontWeight="none"
                fontSize="none"
                textAnchor="none"
                style={{ mixBlendMode: "normal" }}
              >
                <path d="M0,172v-172h172v172z" fill="none" />
                <g>
                  <path
                    d="M2.65391,86c0,-46.02344 37.32266,-83.34609 83.34609,-83.34609c46.02344,0 83.34609,37.32266 83.34609,83.34609c0,46.02344 -37.32266,83.34609 -83.34609,83.34609c-46.02344,0 -83.34609,-37.32266 -83.34609,-83.34609z"
                    fill="#ff5722"
                  />
                  <path
                    d="M137.09609,69.47188l-50.42422,-25.16172c-0.43672,-0.20156 -0.90703,-0.20156 -1.34375,0l-50.45781,25.16172c-0.57109,0.26875 -0.90703,0.80625 -0.90703,1.44453v9.94375c0,0.5375 0.26875,1.04141 0.73906,1.34375c0.47031,0.30234 1.04141,0.33594 1.51172,0.10078l49.78594,-24.85938l49.75234,24.85938c0.20156,0.10078 0.43672,0.16797 0.67188,0.16797c0.30234,0 0.60469,-0.06719 0.83984,-0.23516c0.47031,-0.30234 0.73906,-0.80625 0.73906,-1.34375v-10.01094c0,-0.60469 -0.33594,-1.17578 -0.90703,-1.41094z"
                    fill="#ffffff"
                  />
                  <path
                    d="M127.82422,82.57344l-41.82422,-20.82812l-41.82422,20.82812v38.63281h23.17969v-29.62969h15.95703v29.62969h44.51172z"
                    fill="#ffffff"
                  />
                  <path
                    d="M88.58672,91.57656h10.75v10.75h-10.75z"
                    fill="#ea4949"
                  />
                </g>
              </g>
            </svg>
          </Link>
        </li>
        <li className="customize-list-group-item">
          <Link
            to="/introduction"
            onClick={handleClickIntro}
            activeStyle={{ color: "#fff" }}
          >
            Giới thiệu
          </Link>
        </li>
        <li className="customize-list-group-item">
          <Link to="/homophone" activeStyle={{ color: "#fff" }}>
            Đồng âm
          </Link>
        </li>

        <li className="customize-list-group-item">
          <Link to="/formal" activeStyle={{ color: "#fff" }}>
            In/Formal
          </Link>
        </li>

        <li className="customize-list-group-item">
          <Link
            to="/vietnamese"
            activeStyle={{ color: "#fff" }}
            //   partiallyActive={true}
          >
            Vietnamese
          </Link>
        </li>
      </ListGroup>
    </>
  );
}

export default Menu;
